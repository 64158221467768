//###########################################################################
// Form contact
//###########################################################################

import Vue from 'vue'
Vue.config.productionTip = false
import { scrollTo } from '../utilities/_scrollTo'
import { postToController } from '../utilities/_postToController'
// import { VueReCaptcha } from 'vue-recaptcha-v3'

if (document.querySelector('#form-contact')) {
    new Vue({
        el: '#form-contact',
        delimiters: ['${', '}'],
        data: {
            errors: [],
            subject: '',
            toEmail: '',
            fromName: '',
            firstName: '',
            secondName: '',
            fromEmail: '',
            phoneNumber: '',
            service: '',
            message: '',
            recaptchaVerified: false,
            website: ''
        },
        components: {

        },
        methods: {
            //async recaptcha() {
            //     // (optional) Wait until recaptcha has been loaded.
            //     await this.$recaptchaLoaded()
            //
            //     // Execute reCAPTCHA with action "login".
            //     const token = await this.$recaptcha('contact-form')
            //
            //     // Do stuff with the received token.
            // },
            send: function(e) {
                e.preventDefault()

                this.errors = []

                if (!this.firstName) {
                    this.errors.push("First name is required.")
                }

                if (!this.secondName) {
                    this.errors.push("Second name is required.")
                }

                if (!this.fromEmail) {
                    this.errors.push('Email is required.')
                }

                if (this.fromEmail && !this.validateEmail(this.fromEmail)) {
                    this.errors.push('Email address is incorrect.')
                }

                if (!this.phoneNumber) {
                    this.errors.push('Phone number is required.')
                }

                if (!this.message) {
                    this.errors.push('Message is required.')
                }

                // if (!this.recaptchaVerified) {
                //     this.errors.push('Please complete the Google Recaptcha.')
                // }

                if (this.website !== '') {
                    this.errors.push('An unknown error occured.')
                }

                if (this.errors.length > 0) {
                    // If there's errors, scroll to them and offset by header (because we're scrolling up)
                    const headerHeight = document.querySelector('#header').clientHeight
                    scrollTo(document.querySelector('#form-contact'), -headerHeight)
                }

                if (!this.errors.length) {
                    this.errors = []

                    e.target.submit();

                    // document.querySelector('#form-contact').classList.add("form--submitting")
                    //
                    // let bodyFormData = new FormData()
                    // bodyFormData.append('action', 'contact-form/send')
                    // if (this.toEmail !== '') { bodyFormData.append('toEmail', this.toEmail) }
                    // bodyFormData.append('subject', this.subject)
                    // bodyFormData.append('fromName', this.firstName + ' ' + this.secondName)
                    // bodyFormData.append('fromEmail', this.fromEmail)
                    // bodyFormData.append('message[Phone number]', this.phoneNumber)
                    // bodyFormData.append('message[Service required]', this.service)
                    // bodyFormData.append('message[Message]', this.message)
                    //
                    // postToController(
                    //     '', bodyFormData,
                    //     (response) => {
                    //         // On success, hide contact form and show success message.
                    //         document.querySelector('.form__success').classList.add("is-visible")
                    //         document.querySelector('.form').classList.add("is-hidden")
                    //
                    //         // If there's no errors, scroll to success message and offset by header
                    //         const headerHeight = document.querySelector('#header').clientHeight
                    //         scrollTo(document.querySelector('#form-contact'), -headerHeight)
                    //     },
                    //     (response) => {
                    //         // Handle error
                    //         alert('An error occurred. Please try again.')
                    //     }
                    // );

                }
            },

            handleServices() {
                const self = this

                const serviceToggles = document.querySelectorAll('.contact-block__service-selector__item')

                if (serviceToggles !== null) {
                    for (let i = 0; i < serviceToggles.length; i++) {
                        const serviceToggle = serviceToggles[i]
                        self.handleServiceToggle(serviceToggle)
                    }
                }
            },

            handleServiceToggle(serviceToggle) {
                const self = this

                serviceToggle.addEventListener('click', function(e) {
                    e.preventDefault()

                    // Remove current selected toggle
                    document.querySelector('.contact-block__service-selector__item--selected').classList.remove('contact-block__service-selector__item--selected')

                    // Set current toggle
                    serviceToggle.classList.add('contact-block__service-selector__item--selected')

                    // Set local state
                    self.service = serviceToggle.getAttribute('data-value')
                })
            },

            validateEmail(email) {
                const filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                return filter.test(email)
            },

            verifyRecaptcha() {
                this.recaptchaVerified = true
            },
        },

        mounted() {
            const self = this

            // Initially clear form
            //
            document.querySelector('#form-contact form').reset()

            // Get subject
            //
            this.subject = document.querySelector('#subject').value

            // Get toEmail
            //
            if (document.querySelector('#toEmail') !== null) {
                this.toEmail = document.querySelector('#toEmail').value
            }

            // Get service
            //
            this.service = document.querySelector('.contact-block__service-selector__item--selected').getAttribute('data-value')

            setTimeout(function() {
                // Handle service toggles
                //
                self.handleServices()
            }, 100)
        }
    })
}
