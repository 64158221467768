//##########################################################################
// copy-clipboard.js
//##########################################################################

import ClipboardJS from 'clipboard'

export const copyClipboard = {
    config: {
        copyClipboardBtn: '.copy-clipboard',
    },

    elements: {
        copyClipboardBtns: null
    },

    init() {
        const self = this

        // Local elements
        self.elements.copyClipboardBtns = document.querySelectorAll(self.config.copyClipboardBtn)

        if (self.elements.copyClipboardBtns !== null) {
            for (let i = 0; i < self.elements.copyClipboardBtns.length; i++) {
                let copyClipboardBtn = self.elements.copyClipboardBtns[i]
                self.handleCopyClipboardBtn(copyClipboardBtn)
            }
        }
    },

    handleCopyClipboardBtn(copyClipboardBtn) {
        let clipboard = new ClipboardJS(copyClipboardBtn, {
            container: copyClipboardBtn.parentNode
        });

        clipboard.on('success', function() {
            copyClipboardBtn.setAttribute('data-tooltip', 'Copied link!');

            setTimeout(() => {
                copyClipboardBtn.setAttribute('data-tooltip', 'Copy to Clipboard');
            }, 2000);
        });

        clipboard.on('error', function(e) {
            console.error('Action:', e.action);
            console.error('Trigger:', e.trigger);
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    copyClipboard.init()
})
