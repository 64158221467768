//###########################################################################
// videoModal
//###########################################################################

import GLightbox from 'glightbox'

const videoModal = {
    init() {
        const self = this

        self.handleVideoModal()
    },

    handleVideoModal() {
        const self = this

        const lightbox = GLightbox({
            touchNavigation: true,
            loop: false,
            autoplayVideos: true
        })
    },
}

window.addEventListener('load', function () {
    videoModal.init()
})