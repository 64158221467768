//############################################################################
// Lazysizes
//############################################################################

import Lazysizes from 'lazysizes';

document.addEventListener('lazybeforeunveil', function(e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')'
    }
})