//###########################################################################
// Calendar
//###########################################################################

import Vue from 'vue'
Vue.config.productionTip = false
import datepicker from 'js-datepicker'
import { scrollTo } from '../utilities/_scrollTo'

if (document.querySelector('#calendar')) {
    new Vue({
        el: '#calendar',
        delimiters: ['${', '}'],
        data: {
            events: [],
            eventType: 'all',
            eventRows: null
        },
        methods: {
            initialiseHighlightedDates() {
                const self = this

                const allDateEls = document.querySelectorAll('.calendar .calendar__event__date')

                for (let i = 0; i < allDateEls.length; i++) {
                    const dateEl = allDateEls[i]
                    const day = parseInt(dateEl.getAttribute('data-day'))
                    const month = (parseInt(dateEl.getAttribute('data-month')) - 1) // Account for zero based quick
                    const year = parseInt(dateEl.getAttribute('data-year'))
                    const newDateObject = new Date(year, month, day)

                    this.events.push(newDateObject)
                }
            },

            handleDatepicker() {
                const self = this

                const picker = datepicker('.datepicker', {
                    alwaysShow: true,
                    onSelect: function(instance, event) {
                        self.handleDateSelect(instance, event)
                    },
                    events: this.events
                })
            },

            handleDateSelect(instance) {
                const dateSelected = instance.dateSelected

                if (dateSelected !== undefined) {
                    const day = dateSelected.getDate()
                    const month = dateSelected.getMonth() + 1
                    const year = dateSelected.getFullYear()

                    let hasScrolled = false

                    const dateRowsSelected = document.querySelectorAll('.calendar__event__date[data-day="' + day + '"][data-month="' + month + '"][data-year="' + year + '"]')

                    if (dateRowsSelected !== null) {
                        for (let i = 0; i < dateRowsSelected.length; i++) {
                            const rowSelected = dateRowsSelected[i].parentNode

                            if (hasScrolled === false) {
                                // Scroll to the list initially
                                //
                                const headerHeight = document.querySelector('#header').clientHeight
                                scrollTo(document.querySelector('.calendar__list'), -headerHeight)

                                // Scroll the table body to the specific row if not visible
                                //
                                const tbody = document.querySelector('.calendar__list tbody')
                                this.scrollToTableRow(tbody, rowSelected)

                                // Set local state so it can't trigger scroll more than once per click
                                //
                                hasScrolled = true

                                // Highlight row
                                //
                                setTimeout(function () {
                                    rowSelected.classList.add('highlight')
                                }, 200)
                            } else {
                                // Highlight row
                                //
                                rowSelected.classList.add('highlight')
                            }
                        }

                        // Remove highlight classes after animation
                        //
                        setTimeout(function() {
                            for (let i = 0; i < dateRowsSelected.length; i++) {
                                const rowSelected = dateRowsSelected[i].parentNode

                                rowSelected.classList.remove('highlight')
                            }
                        }, 1500)
                    }
                }

                // Reset filter
                //
                if (this.eventType !== 'all') {
                    this.eventType = 'all'
                    document.querySelector('.calendar__filter__radio input#all').checked = true
                    this.resetAllRows()
                }
            },

            scrollToTableRow(parent, child) {
                // Where is the parent on page
                let parentRect = parent.getBoundingClientRect()
                // What can you see?
                let parentViewableArea = {
                    height: parent.clientHeight,
                    width: parent.clientWidth
                }

                // Where is the child
                let childRect = child.getBoundingClientRect()
                // Is the child viewable?
                let isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height)

                // if you can't see the child try to scroll parent
                if (!isViewable) {
                    // Should we scroll using top or bottom? Find the smaller ABS adjustment
                    const scrollTop = childRect.top - parentRect.top
                    const scrollBot = childRect.bottom - parentRect.bottom
                    if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
                        // we're near the top of the list
                        parent.scrollTop += scrollTop
                    } else {
                        // we're near the bottom of the list
                        parent.scrollTop += scrollBot
                    }
                }
            },

            handleFilter(event) {
                const selectedRadio = event.target

                if (selectedRadio.checked === true) {
                    if (selectedRadio.id == 'all') {
                        // Remove .hidden from all rows
                        //
                        this.resetAllRows()
                    } else {
                        // Remove .hidden from all rows
                        //
                        this.resetAllRows()

                        // Set .hidden on all classes except selected filter type rows
                        //
                        this.filterEventTypeRows(selectedRadio.id)
                    }
                }
            },

            resetAllRows() {
                for (let i = 0; i < this.eventRows.length; i++) {
                    const eventRow = this.eventRows[i]

                    eventRow.classList.remove('hidden')
                }
            },

            filterEventTypeRows(eventType) {
                for (let i = 0; i < this.eventRows.length; i++) {
                    const eventRow = this.eventRows[i]

                    if (eventRow.getAttribute('data-event-type') !== eventType) {
                        eventRow.classList.add('hidden')
                    }
                }
            }
        },

        mounted() {
            // Initialise dates from date <td> cells
            //
            this.initialiseHighlightedDates()

            // Initialise datepicker
            //
            this.handleDatepicker()

            // Set local elements
            //
            this.eventRows = document.querySelectorAll('.calendar__event')
        }
    })
}
