//###########################################################################
// sliderStories
//###########################################################################

import Flickity from 'flickity'
import { throttle } from '../utilities/_throttle-debounce'

const sliderStories = {
    config: {
        sliderStoriesContainer: '.slider-stories__container',
        sliderStoriesInner: '.slider__inner',
        sliderStories: '.slider-stories',
        prevBtn: '.slider__control--prev',
        nextBtn: '.slider__control--next',
        wrapperRef: '#wrapper-ref'
    },

    elements: {
        sliderStoriesContainer: null,
        sliderStories: null,
        prevBtn: null,
        nextBtn: null,
        wrapperRef: null
    },

    init() {
        const self = this

        self.elements.sliderStoriesContainer = document.querySelector(self.config.sliderStoriesContainer)

        if (self.elements.sliderStoriesContainer !== null) {
            self.elements.wrapperRef = document.querySelector(self.config.wrapperRef)
            self.elements.sliderStoriesInner = document.querySelector(self.config.sliderStoriesInner)
            self.elements.sliderStories = self.elements.sliderStoriesContainer.querySelector(self.config.sliderStories)
            self.elements.prevBtn = self.elements.sliderStoriesContainer.querySelector(self.config.prevBtn)
            self.elements.nextBtn = self.elements.sliderStoriesContainer.querySelector(self.config.nextBtn)

            self.handleSliderContainerAlignment()
            self.handleSlider()
        }
    },

    handleSlider() {
        const self = this

        const flktyInstance = new Flickity(self.elements.sliderStories, {
            cellSelector: '.slider__item',
            cellAlign: 'left',
            wrapAround: true,
            pageDots: false,
            prevNextButtons: false,
            percentPosition: true,
            adaptiveHeight: true,
            groupCells: true
        })

        flktyInstance.on('ready', function() {
            flktyInstance.resize()
        })

        self.elements.prevBtn.addEventListener('click', function () {
            flktyInstance.previous()
        })

        self.elements.nextBtn.addEventListener('click', function () {
            flktyInstance.next()
        })
    },

    handleSliderContainerAlignment() {
        const self = this

        let leftPos = self.elements.wrapperRef.getBoundingClientRect().left + 13.3334

        if (window.innerWidth > 992) {
            self.elements.sliderStoriesInner.style.paddingLeft = leftPos + 'px'
        }

        window.addEventListener('resize', throttle(function() {
            leftPos = self.elements.wrapperRef.getBoundingClientRect().left + 13.3334

            if (window.innerWidth > 992) {
                self.elements.sliderStoriesInner.style.paddingLeft = leftPos + 'px'
            } else {
                self.elements.sliderStoriesInner.style.paddingLeft = ''
            }
        }, 500))
    }
}

window.addEventListener('load', function () {
    sliderStories.init()
})