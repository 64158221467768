//###########################################################################
// Form generic
//###########################################################################

import Vue from 'vue'
Vue.config.productionTip = false
import { scrollTo } from '../utilities/_scrollTo'
import { postToController } from '../utilities/_postToController'
import { VueRecaptcha } from 'vue-recaptcha'

if (document.querySelector('#form-generic')) {
    new Vue({
        el: '#form-generic',
        delimiters: ['${', '}'],
        data: {
            errors: [],
            subject: '',
            fromName: '',
            firstName: '',
            secondName: '',
            fromEmail: '',
            toEmail: '',
            phoneNumber: '',
            message: '',
            recaptchaVerified: false,
            website: ''
        },
        components: {
            VueRecaptcha
        },
        methods: {
            send: function(e) {
                e.preventDefault()

                this.errors = []

                if (!this.firstName) {
                    this.errors.push("First name is required.")
                }

                if (!this.secondName) {
                    this.errors.push("Second name is required.")
                }

                if (!this.fromEmail) {
                    this.errors.push('Email is required.')
                }

                if (this.fromEmail && !this.validateEmail(this.fromEmail)) {
                    this.errors.push('Email address is incorrect.')
                }

                if (!this.phoneNumber) {
                    this.errors.push('Phone number is required.')
                }

                if (!this.message) {
                    this.errors.push('Message is required.')
                }

                // if (!this.recaptchaVerified) {
                //     this.errors.push('Please complete the Google Recaptcha.')
                // }

                if (this.website !== '') {
                    this.errors.push('An unknown error occured.')
                }

                if (this.errors.length > 0) {
                    // If there's errors, scroll to them and offset by header (because we're scrolling up)
                    const headerHeight = document.querySelector('#header').clientHeight
                    scrollTo(document.querySelector('#form-generic'), -headerHeight)
                }

                if (!this.errors.length) {
                    this.errors = []

                    e.target.submit();


                    // document.querySelector('#form-generic').classList.add("form--submitting")
                    //
                    // let bodyFormData = new FormData()
                    // bodyFormData.append('action', 'contact-form/send')
                    // if (this.toEmail !== '') { bodyFormData.append('toEmail', this.toEmail); console.log('toEmail set'); }
                    // bodyFormData.append('subject', this.subject)
                    // bodyFormData.append('fromName', this.firstName + ' ' + this.secondName)
                    // bodyFormData.append('fromEmail', this.fromEmail)
                    // bodyFormData.append('message[Phone number]', this.phoneNumber)
                    // bodyFormData.append('message[Message]', this.message)
                    //
                    // postToController(
                    //     '', bodyFormData,
                    //     (response) => {
                    //         // On success, hide contact form and show success message.
                    //         document.querySelector('.form__success').classList.add("is-visible")
                    //         document.querySelector('.form').classList.add("is-hidden")
                    //
                    //         // If there's no errors, scroll to success message and offset by header
                    //         const headerHeight = document.querySelector('#header').clientHeight
                    //         scrollTo(document.querySelector('#form-generic'), -headerHeight)
                    //     },
                    //     (response) => {
                    //         // Handle error
                    //         alert('An error occurred. Please try again.')
                    //     }
                    // );

                }
            },

            validateEmail(email) {
                const filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                return filter.test(email)
            },

            verifyRecaptcha() {
                this.recaptchaVerified = true
            },
        },

        mounted() {
            // Initially clear form
            //
            document.querySelector('#form-generic form').reset()

            // Get initial values
            //
            if (document.querySelector('#toEmail') !== null) {
                this.toEmail = document.querySelector('#toEmail').value
            }

            this.subject = document.querySelector('#subject').value

            console.log('Email: ' + this.toEmail, 'Subject: ' + this.subject)
        }
    })
}
