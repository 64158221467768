//###########################################################################
// Cookie message
//###########################################################################

import Cookies from 'js-cookie'

// Check current cookies
cookie()

// Event handlers
if (document.getElementById("cookie-accept") !== null) {
    document.getElementById("cookie-accept").addEventListener("click", function (e) {
        cookieAccept(e, this)
    })
}

if (document.getElementById("cookie-change") !== null) {
    document.getElementById("cookie-change").addEventListener("click", function (e) {
        cookieAccept(e, this)
    })
}

if (document.getElementById("cookie-update") !== null) {
    document.getElementById("cookie-update").addEventListener("click", function(e) {
        cookieUpdate(e, this )
    })
}

// Functions
function cookie() {
    let cookieAccepted = 'cookie-accepted';
    let getAccepted = Cookies.get(cookieAccepted)

    if (!getAccepted && document.getElementById("cookie-message") !== null) {
        document.getElementById("cookie-message").style.display = 'block'
    }
}

function cookieAccept(e, that) {
    e.preventDefault()
    let button = that.getAttribute('id')

    let cookieAccepted = 'cookie-accepted'
    let cookieFunc = 'cookie-functional'
    let cookieAna = 'cookie-analytics'
    let cookieVert = 'cookie-vert'
    let cookieOther = 'cookie-other'
    let getAccepted = Cookies.get(cookieAccepted)
    let getFunc = Cookies.get(cookieFunc)
    let getAna = Cookies.get(cookieAna)
    let getVert = Cookies.get(cookieVert)
    let getOther = Cookies.get(cookieOther)

    if (!getAccepted) {
        let cookieData = {
            "cookie-functional": 1,
            "cookie-analytics": 1,
            "cookie-vert": 1,
            "cookie-other": 1,
            "date": +new Date(),
            "version": 1
        }
        Cookies.set(cookieAccepted, cookieData)
    }

    if (!getFunc) {
        Cookies.set(cookieFunc, 1)
    }

    if (!getAna) {
        Cookies.set(cookieAna, 1)
    }

    if (!getVert) {
        Cookies.set(cookieVert, 1)
    }

    if (!getOther) {
        Cookies.set(cookieOther, 1)
    }

    if (button === "cookie-accept") {
        location.reload()
    } else {
        window.location = this.this.getAttribute('href')
    }
}

function cookieUpdate(e) {
    e.preventDefault();

    let cookieAccepted = 'cookie-accepted'
    let cookieFunc = 'cookie-functional'
    let cookieAna = 'cookie-analytics'
    let cookieVert = 'cookie-vert'
    let cookieOther = 'cookie-other'

    let cookiefuncValue = document.getElementById('cookie-functional').checked ? 1 : 0
    let cookieAnaValue = document.getElementById('cookie-analytics').checked ? 1 : 0
    let cookieVertValue = document.getElementById('cookie-vert').checked ? 1 : 0
    let cookieOtherValue = document.getElementById('cookie-other').checked ? 1 : 0

    let cookieData = {
        "cookie-functional": cookiefuncValue,
        "cookie-analytics": cookieAnaValue,
        "cookie-vert": cookieVertValue,
        "cookie-other": cookieOtherValue,
        "date": +new Date(),
        "version": 1
    };

    Cookies.set(cookieAccepted, cookieData)
    Cookies.set(cookieFunc, cookiefuncValue)
    Cookies.set(cookieAna, cookieAnaValue)
    Cookies.set(cookieVert, cookieVertValue)
    Cookies.set(cookieOther, cookieOtherValue)

    window.location = "/"
}

