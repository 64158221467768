//###########################################################################
// Filters
//###########################################################################

import { scrollTo } from "../utilities/_scrollTo";

const filters = {
    config: {
        filter: '.filter__category-select',
        filterPaginationBtn: '.pagination__arrow, .pagination__number'
    },

    elements: {
        filters: null,
        filterPaginationBtns: null
    },

    init() {
        const self = this

        self.elements.filters = document.querySelectorAll(self.config.filter)

        if (self.elements.filters.length > 0) {
            self.elements.filterPaginationBtns = document.querySelectorAll(self.config.filterPaginationBtn)

            for (let i = 0; i < self.elements.filters.length; i++) {
                const filter = self.elements.filters[i]

                filter.addEventListener('change', function () {
                    // Scroll to entries
                    self.scrollToEntryContainer()
                })
            }

            // Handle pagination buttons
            //
            self.initPaginationBtns()

            // Add loading state
            //
            htmx.on('htmx:beforeRequest', function (event) {
                document.querySelector('#entry-container').classList.add('is-loading')
            })

            // After swap re-add event listeners for the above and remove loading state
            //
            htmx.on('htmx:afterSwap', function(event) {
                document.querySelector('#entry-container').classList.remove('is-loading')

                setTimeout(function() {
                    self.elements.filterPaginationBtns = document.querySelectorAll(self.config.filterPaginationBtn)

                    self.initPaginationBtns()
                }, 500)
            })
        }
    },

    initPaginationBtns() {
        const self = this

        for (let i = 0; i < self.elements.filterPaginationBtns.length; i++) {
            const filterPaginationBtn = self.elements.filterPaginationBtns[i]

            filterPaginationBtn.addEventListener('click', function (event) {
                event.preventDefault()

                self.handlePaginationClick()
            })
        }
    },

    handlePaginationClick() {
        const self = this

        // Scroll to entries
        self.scrollToEntryContainer()
    },

    scrollToEntryContainer() {
        const offsetAmount = document.querySelector('#header').clientHeight + 40
        scrollTo(document.querySelector('#entry-container'), -offsetAmount)
    }
}

window.addEventListener('load', function () {
    filters.init()
})