//###########################################################################
// sliderGallery
//###########################################################################

import Flickity from 'flickity'

const sliderGallery = {
    config: {
        sliderGallery: '.slider-gallery',
        prevBtn: '.slider__control--prev',
        nextBtn: '.slider__control--next',
    },

    elements: {
        sliderGallery: null,
        prevBtn: null,
        nextBtn: null,
    },

    init() {
        const self = this

        self.elements.sliderGallery = document.querySelector(self.config.sliderGallery)

        if (self.elements.sliderGallery !== null) {
            self.elements.prevBtn = self.elements.sliderGallery.querySelector(self.config.prevBtn)
            self.elements.nextBtn = self.elements.sliderGallery.querySelector(self.config.nextBtn)

            self.handleSlider()
        }
    },

    handleSlider() {
        const self = this

        const flktyInstance = new Flickity(self.elements.sliderGallery, {
            cellSelector: '.slider__item',
            cellAlign: 'left',
            wrapAround: true,
            pageDots: false,
            prevNextButtons: false,
            percentPosition: true,
            adaptiveHeight: true,
            groupCells: true
        })

        flktyInstance.on('ready', function() {
            flktyInstance.resize()
        })

        self.elements.prevBtn.addEventListener('click', function () {
            flktyInstance.previous()
        })

        self.elements.nextBtn.addEventListener('click', function () {
            flktyInstance.next()
        })
    },
}

window.addEventListener('load', function () {
    sliderGallery.init()
})