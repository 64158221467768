//###########################################################################
// browserDetect
//###########################################################################

const browserDetect = {
    init() {
        const self = this

        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

        if (isSafari === true) {
            document.querySelector('body').classList.add('safari')
        }
    }
}

window.addEventListener('load', function () {
    browserDetect.init()
})